import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import CompServices from '../components/comp-services'

const Services = () => {

  return (
    <Layout>

      <SEO title="Services" />

      <CompServices />

    </Layout>
  )
}

export default Services